<template>
  <div>
    <div>
      <el-button type="primary">主要按钮</el-button>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  name: '',
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
  },
  destroyed() {},
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media (max-width: 599px) {
}
@media (min-width: 599px) {
}
@media (min-width: 960px) {
}
@media (min-width: 990px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1440px) {
}
</style>
